<p-confirmDialog appendTo="body" [baseZIndex]="10000" #confirmDialog [style]="{width: '50vw'}"></p-confirmDialog>

<div class="content-wrapper container-m mr-6">
    <div class="content-body">
      <div class="row">
        <div class="col-md-12">
          <div style="margin-top: 3%;">
            <div class="row">
              <div class="col-md-12">
                <div class="card">
                  <div class="card-header">
                    <div class="row col-12">
                      <div class="col-lg-11 col-md-11 col-12">
                        <h2 class="card-title">Satınalma Talep Detayları</h2>
                      </div>
  
                      <div class="col-lg-1 col-md-1 col-12">
                        <div style="display:flex; justify-content: end;">
                          <button class="btn btn-success btn-sm mr-2 saveButton"
                            (click)="updatePurchaseEnquiry()"
                            title="Kaydet"><i data-feather="save"></i></button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="card-body">
                    <div class="table-responsive">
                      <table class="table">
                        <thead class=" text-primary">
                          <th></th>
                          <th></th>  
                          <th colspan="2"></th>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Talep No:</td>
                            <td>
                              {{purchaseEnquiry.purchaseOrderNo}}
                            </td>
                            <td>Talep Sorumlusu:</td>
                            <td>
                                <p-dropdown [options]="users"
                                [(ngModel)]="purchaseEnquiry.requesterId" 
                                optionValue="id"
                                optionLabel="fullName"
                                [style]="{'width':'100%'}"
                                [filter]="true"
                                filterBy="fullName"
                                [autoWidth]="true" 
                                >
                                </p-dropdown>    

                            </td>

                          </tr>
                          <tr>
                            <td> Talep Tarihi:</td>
                            <td>
                                <input type="datetime-local" name="txtPurchaseDate" #txtPaymentDate
                                class="form-control" [ngModel]="purchaseEnquiry.purchaseDate | date:'yyyy-MM-ddTHH:mm'"
                                (ngModelChange)="purchaseEnquiry.purchaseDate = $event" ngModel required #txtPurchaseDate="ngModel">
        
                            </td>
                            <td>Talep Son Tarih:</td>
                            <td>
                                <input type="datetime-local" name="txtPurchaseDeadline" #txtPaymentDeadline
                                class="form-control" [ngModel]="purchaseEnquiry.purchaseDeadline | date:'yyyy-MM-ddTHH:mm'"
                                (ngModelChange)="purchaseEnquiry.purchaseDeadline = $event" ngModel required #txtPurchaseDeadline="ngModel">
                            </td>
                          </tr>
                          <tr>
                            <td>Açıklama:</td>
                            <td colspan="3" class="DetailsLeftSide">
                              <textarea type="text" required id="txtSubject" class="form-control"
                                [(ngModel)]="purchaseEnquiry.description" name="txtSubject" placeholder="Konu"
                                #txtSubject="ngModel"></textarea>
                            </td>
                          </tr>
                          <tr>
                            <td></td>
                            <td></td>
                            <td colspan="2" class="DetailsRightSide">
                              <div class="row">
                                <div class="col-3">
                                </div>
                                <div class="col-9" style="text-align:center; ">
                                  Talebi Açan Kullanıcı
                                  <div class="badge badge-pill badge-light-success font-small-3 ">
                                    {{purchaseEnquiry.requesterName}}
                                  </div>
                                </div>
                              </div>
                            </td>
  
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <br>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row mb-4">
        <div class="col-12">
            <div class="card">
                <div class="card-header">
                <h2 class="card-title">Ürün Bilgileri</h2>
                </div>
                <div class="card-body">

                <div class="row mb-2">
                    <div class="firstRow mt-1">
                        <div class="col-12">
                            <input type="text" class="form-control" name="filterText" placeholder="Ürün Arayın.."
                                [(ngModel)]="filterText" [ngModelOptions]="{standalone:true}">
                        </div>
                    </div>
                    <div class="filter mt-1">
                        <div style="margin-left:9px;">
                            <div class="col-12">
                                <ng-select [multiple]="true" [items]="approvalStatuses" [(ngModel)]="selectedApprovalStatus"
                                    placeholder="Onay Durumu Seçiniz " bindLabel="label" bindValue="id"
                                    (change)="filterTable()">
                                    <ng-template ng-label-tmp let-item="item" let-clear="clear">
                                        <span class="ng-value-label">{{ item.label}}</span>
                                        <span class="ng-value-icon right" (click)="clear(item)"
                                            aria-hidden="true">×</span>
                                    </ng-template>
                                    <ng-template ng-header-tmp>
                                    </ng-template>
                                </ng-select>
                            </div>
        
                        </div>
                    </div>
                </div>

                <div class="mb-2">
                    <div *ngIf="userRole=='Customer'||userRole=='CustomerUser'">
                    <button class="btn btn-secondary btn-sm" title="Excele Aktar" (click)="exportExcel()"><i
                        data-feather="file-text"></i><span></span></button>
                    </div>
                    <div *ngIf="userRole=='Admin'||userRole=='ConsultantUser' || userRole=='Consultant'">
                    <button class="btn btn-primary mr-2 btn-sm" title="Yeni Ürün Ekle" (click)="clearItem()" data-bs-toggle="modal"
                        data-bs-target="#addPurchaseItem"><i data-feather="plus"></i><span></span></button>
                    <button class="btn btn-secondary btn-sm" title="Excele Aktar" (click)="exportExcel()"><i
                        data-feather="file-text"></i><span></span></button>
                    </div>

                </div>
                <table class="table table-hover table-bordered" id="excel-table" style="display: none;">
                    <thead>
                    <th>Adı</th>
                    <th>Miktar</th>
                    <th>Tahmini Market Fiyatı (TL)</th>
                    <th>Onay Durumu</th>
                    <th>Onay Açıklaması</th>
                    <th>Satan Firmalar</th>
                    <th>Özellikler</th>
                    <th>Ek Bilgi</th>
                    <th>Sipariş Tarihi</th>
                    <th>İrsaliye Tarihi</th>
                    <th>Fatura Tarihi</th>
                    </thead>
                    <tbody>
                    <tr *ngFor="let item of purchaseItemList">
                        <td>{{item.name}}</td>
                        <td>{{item.quantity}}</td>
                        <td>{{item.estimatedMarketPrice}}</td>
                        <td>
                            <div *ngIf="item.approvalStatus==1">Onay Bekleniyor</div>
                            <div *ngIf="item.approvalStatus==2">Onaylandı</div>
                            <div *ngIf="item.approvalStatus==3">Reddedildi</div>
                        </td>
                        <td>{{item.approvalDescription}}</td>
                        <td>{{item.potentialSuppliers}}</td>
                        <td>{{item.technicalQualities}}</td>
                        <td>{{item.additionalInfo}}</td>
                        <td>{{item.orderDate | date:"dd.MM.yyyy HH:mm:ss"}}</td>
                        <td>{{item.dispatchDate | date:"dd.MM.yyyy HH:mm:ss"}}</td>
                        <td>{{item.invoiceDate | date:"dd.MM.yyyy HH:mm:ss"}}</td>
                    </tr>
                    </tbody>
                </table>
                <div class="table-responsive">
                    <table class="table table-hover table-stripped">
                      <thead>
                          <th>İşlemler</th>
                          <th>Adı</th>
                          <th>Miktar</th>
                          <th>Market Fiyatı (TL)</th>
                          <th>Onay Durumu</th>
                          <th>Sipariş Tarihi</th>
                          <th>İrsaliye Tarihi</th>
                          <th>Fatura Tarihi</th>
                      </thead>
                      <tr *ngFor="let item of filteredPurchaseItemList | filter: filterText | paginate: { itemsPerPage: 5, currentPage: page }">
                            <td>
                              <div class="d-flex align-items-center">
                                <div ngbDropdown container="body">
                                    <a ngbDropdownToggle href="javascript:void(0);" class="hide-arrow" id="dropdownBrowserState"
                                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <i data-feather="more-vertical" class="text-primary cursor-pointer mr-50"></i>
                                    </a>
                                    <div ngbDropdownMenu class="dropdown-menu-right" aria-labelledby="dropdownBrowserState">
                                    <a ngbDropdownItem (click)="getItem(item.id)" data-bs-toggle="modal"
                                    data-bs-target="#addPurchaseItem">
                                        <i data-feather="edit" class="mr-50"></i><span>Güncelle</span>
                                    </a>
                                    <a ngbDropdownItem (click)="delete(item.id);">
                                        <i data-feather="trash" class="mr-50"></i><span>Sil</span>
                                    </a>
                                    </div>
                                </div>
                              </div>
                          </td>
                          <td>{{item.name}}</td>
                          <td>{{item.quantity}}</td>
                          <td>{{item.estimatedMarketPrice}}</td>
                          <td>
                              <div class="badge badge-pill badge-light-secondary mr-1" *ngIf="item.approvalStatus==1">Onay Bekleniyor</div>
                              <div class="badge badge-pill badge-light-success mr-1" *ngIf="item.approvalStatus==2">Onaylandı</div>
                              <div class="badge badge-pill badge-light-danger mr-1" *ngIf="item.approvalStatus==3">Reddedildi</div>
                          </td>
                          <td>
                            
                            {{item.orderDate != null ? (item.orderDate | date:"dd.MM.yyyy HH:mm") : "Girilmemiş"}}
                          </td>
                          <td>{{item.dispatchDate != null ? (item.dispatchDate | date:"dd.MM.yyyy HH:mm") : "Girilmemiş"}}</td>
                          <td>{{item.invoiceDate != null ? (item.invoiceDate | date:"dd.MM.yyyy HH:mm") : "Girilmemiş"}}</td>

                      </tr>
                    </table>

                    <div class="pagination-controls-container">
                      <pagination-controls (pageChange)="page = $event"
                                           previousLabel="Geri"
                                           nextLabel="İleri">
                      </pagination-controls>
                    </div>

                </div>
              </div>
            </div>
        </div>
      </div>
      
      <div class="row mb-4">
        <div class="col-12">
          <div class="card">
            <div class="card-header">
              <div class="card-title">Ek Dosyalar</div>
            </div>
            <div class="card-body">
              <div style="margin: 5px 0px 0px 0px;">
                <fieldset class="form-group">
                    <label for="file-upload-multiple">Dosyalar</label>
                    <div class="custom-file">
                        <input class="custom-file-input" type="file"
                            (change)="getDocuments($event)" #documents
                            id="file-upload-multiple" ng2FileSelect [uploader]="uploader"
                            multiple />
                        <label class="custom-file-label" for="file-upload-multiple">Dosya
                            Seçiniz</label>
                    </div>
                </fieldset>
            </div>

            <div class="col-lg-12 overflow-auto">
                <div class="d-flex justify-content-between mb-1">
                    <p>Seçilen Dosya Sayısı: {{ uploader?.queue?.length }}</p>
                </div>

                <table class="table">
                    <thead>
                        <tr>
                            <th>Adı</th>
                            <th>Boyutu</th>
                            <th>İşlemler</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of uploader.queue">
                            <td>
                                <strong>{{ item?.file?.name }}</strong>
                            </td>
                            <td *ngIf="uploader.options.isHTML5" nowrap>
                                {{ item?.file?.size / 1024 / 1024 | number: '.2' }} MB
                            </td>
                            <td nowrap>
                                <button type="button"
                                    class="btn btn-raised btn-outline-success btn-sm mr-1"
                                    (click)="downloadImage(item.file.name)" rippleEffect>
                                    <span data-feather="upload"></span>
                                </button>
                                <button type="button"
                                    class="btn btn-raised btn-outline-primary btn-sm mr-1"
                                    data-bs-toggle="modal"
                                    data-bs-target="#showpriviewimage"
                                    (click)="previewImage(item)" rippleEffect>
                                    <i data-feather="eye"></i>
                                </button>

                                <button type="button"
                                    class="btn btn-raised btn-outline-danger btn-sm "
                                    (click)="item.remove()" rippleEffect>
                                    <i data-feather="trash"></i>
                                </button>
                            </td>
                        </tr>
                    </tbody>

                </table>
                <div id="">
                    <p class="mt-1">Dosyanın Aşaması:</p>
                    <ngb-progressbar class="mb-1" type="primary"
                        [value]="uploader.progress"></ngb-progressbar>
                    <button type="button" class="btn btn-outline-danger mb-1 mb-sm-0"
                        (click)="uploader.clearQueue()" [disabled]="!uploader.queue.length"
                        rippleEffect>
                        <span data-feather="trash" class="mr-25"></span>Hepsini Sil
                    </button>
                </div>
            </div>
            </div>
          </div>
        </div>
      </div>
    
    </div>
</div>


<div class="modal fade" id="addPurchaseItem" tabindex="-1" aria-labelledby="staticBackdropLabel"
aria-hidden="true">
<div class="modal-dialog modal-lg">
  <div class="modal-content">
    <div class="modal-header">
      <h1 class="modal-title fs-5" id="staticBackdropLabel">Ürün Ekle</h1>
      <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"><i data-feather="x-circle"
          class="mr-50"></i>Çıkış</button>
    </div>
    <form class="form form-vertical" #purchaseEnquiryForm="ngForm">
      <div class="modal-body">
          <div class="form-group">
                <div class="row mb-2 pl-1 pr-1">
                    <label for="usrSelect">Adı</label>
                    <input type="text" class="form-control" ngModel required name="txtItemName" [(ngModel)]="purchaseItem.name"
                    #txtItemName="ngModel">
                    <small class="text-danger" *ngIf="txtItemName.invalid&& txtItemName.touched">Bir ad girmeniz gerekiyor!</small>
                </div>
                <div class="row mb-2 pl-1 pr-1">
                    <label for="usrSelect">Miktar</label>
                    <input type="number" class="form-control" ngModel required name="txtItemQuantity" [(ngModel)]="purchaseItem.quantity"
                    #txtItemQuantity="ngModel">
                    <small class="text-danger" *ngIf="txtItemQuantity.invalid&& txtItemQuantity.touched">Miktar girmeniz gerekiyor!</small>
                </div>
                <div class="row mb-2 pl-1 pr-1">
                    <label for="usrSelect">Tahmini Market Fiyatı (TL)</label>
                    <input type="number" class="form-control" ngModel required name="txtEstimatedMarketPrice" [(ngModel)]="purchaseItem.estimatedMarketPrice"
                    #txtEstimatedMarketPrice="ngModel">
                    <small class="text-danger" *ngIf="txtEstimatedMarketPrice.invalid&& txtEstimatedMarketPrice.touched">Tahmini market fiyatı girmeniz gerekiyor!</small>
                </div>
                <div class="row mb-2 pl-1 pr-1">
                    <label for="usrSelect">Özellikler</label>
                    <textarea class="form-control" ngModel name="txtTechnicalQualities" [(ngModel)]="purchaseItem.technicalQualities"
                    #txtTechnicalQualities="ngModel"></textarea>
                </div>
                <div class="row mb-2 pl-1 pr-1">
                    <label for="usrSelect">Ek Bilgi</label>
                    <textarea class="form-control" ngModel name="txtAdditionalInfo" [(ngModel)]="purchaseItem.additionalInfo"
                    #txtAdditionalInfo="ngModel"></textarea>
                </div>
                <div class="row mb-2 pl-1 pr-1">
                    <label for="usrSelect">Satan Firmalar</label>
                    <input type="text" class="form-control" ngModel name="txtPotentialSuppliers" [(ngModel)]="purchaseItem.potentialSuppliers"
                    #txtPotentialSuppliers="ngModel">
                </div>
                <div *ngIf="userRole=='Admin' && purchaseItem.id!=0">
                    <div class="row mb-2 pl-1 pr-1">
                        <label for="usrSelect">Onay Durumu Seç</label>
                        <select class="form-control" ngModel required name="slcApproval" [(ngModel)]="purchaseItem.approvalStatus"
                        #slcApproval="ngModel">
                        <option *ngFor="let item of approvalStatuses" value="{{item.id}}">{{item.label}}</option>
                        </select>
                    </div>
                    <div class="row mb-2 pl-1 pr-1">
                        <label for="usrSelect">Onay Açıklaması</label>
                        <input type="text" class="form-control" ngModel name="txtApprovalDescription" [(ngModel)]="purchaseItem.approvalDescription"
                        #slcApproval="ngModel">
                    </div>

                    <div class="row mb-2 pl-1 pr-1">
                      <label for="name-vertical">Sipariş Tarihi</label>
                      <input type="datetime-local" name="txtOrderDate"
                          class="form-control" [ngModel]="purchaseItem.orderDate | date:'yyyy-MM-ddTHH:mm'"
                          (ngModelChange)="purchaseItem.orderDate = $event" ngModel #txtOrderDate="ngModel">
  
                    </div>

                    <div class="row mb-2 pl-1 pr-1">
                      <label for="name-vertical">İrsaliye Tarihi</label>
                      <input type="datetime-local" name="txtDispatchDate"
                          class="form-control" [ngModel]="purchaseItem.dispatchDate | date:'yyyy-MM-ddTHH:mm'"
                          (ngModelChange)="purchaseItem.orderDate = $event" ngModel #txtDispatchDate="ngModel">
                    </div>

                    <div class="row mb-2 pl-1 pr-1">
                      <label for="name-vertical">Fatura Tarihi</label>
                      <input type="datetime-local" name="txtInvoiceDate"
                          class="form-control" [ngModel]="purchaseItem.invoiceDate | date:'yyyy-MM-ddTHH:mm'"
                          (ngModelChange)="purchaseItem.invoiceDate = $event" ngModel #txtInvoiceDate="ngModel">
                    </div>
                </div>
          </div>
      </div>
      <div class="modal-footer">
          <button type="button" (click)="processPending=true;savePurchaseItem()" rippleEffect class="btn btn-success mr-1 cursor-not-allowed"
          [disabled]="!purchaseEnquiryForm.valid || processPending==true">Kaydet</button>
      </div>
   </form>      
  </div>
</div>
</div>