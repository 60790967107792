export class Task {
    id: number;
    title: string;
    description: string;
    userId: number;
    typeId: number;
    startDate: Date = new Date();
    endDate: Date = new Date(new Date().setHours((new Date().getHours()+1)));
    isReccuren: boolean;
    isImportant: boolean;
    isCompleted: boolean;
    statusId: number;
    statusName
    createdBy: number;
    createdDate: Date;
    updatedBy: number;
    deletedBy: number;
    frequencyTypeId? :number;
    userName:string;
}