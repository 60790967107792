<core-card-snippet [snippetCode]="_snippetCodeSwiperCoverflow">
    <div class="card-title">
        {{Month}} Ayı
    </div>
    <div class="row mb-2">
        <div class="col-5">
            <div class="col-12">
                <button class="btn btn-primary btn-sm mr-1" data-bs-toggle="modal" data-bs-target="#addexpense"
                    rippleEffect title="Yeni Masraf" (click)="clearFileQueue()">
                    <i data-feather="plus" class="mr-0"></i><span class="d-none d-sm-inline-block"></span>
                </button>
                <button class="btn btn-info btn-sm" (click)="exportExcelMyList()" rippleEffect title="Excele Aktar">
                    <i data-feather="file-text"></i>
                </button>
            </div>
        </div>
        <div class="col-5">

        </div>
        <div class="col-2">
            Toplam: <div class="badge badge-pill badge-danger font-small-3 font-weight-bold line-height-2 mb-25">
                {{total}} TL
            </div>

        </div>
    </div>

    <table class="table table-hover table-bordered" id="table-excel-my-list" style="display: none;">
        <tbody>
            <tr>
                <td>Tarih</td>
                <td>Kullanıcının Adı</td>
                <td>Açıklama</td>
                <td>Tutar</td>
                <td>Masraf Türü</td>
                <td>Proje Adı</td>
                <td style="display:none;">İzinli Mi?</td>

            </tr>
            <tr *ngFor="let item of expenses">
                <td [innerHTML]="item.paymentDate | date:'yyyy-MM-dd HH:mm:ss'"></td>
                <td>{{item.userName}}</td>
                <td>{{item.description}}</td>
                <td>{{item.payment}}</td>
                <td>{{item.typeName}}</td>
                <td>{{item.projectTitle}}</td>
                <td style="display:none;">{{item.userOnLeave==1 ? "Evet" : "Hayır"}}</td>

            </tr>
        </tbody>
    </table>
    <div class="card-body">
        <swiper class="swiper-coverflow swiper-container" [config]="swiperCoverflow">
            <div *ngFor="let item of expenses">
                <div class="card" style="border-radius:50px">
                    <div class="card-title">
                        {{item.projectTitle}}
                        <div class="d-flex justify-content-md-end">
                            <div class="d-flex align-items-center justify-content-end">
                                <div ngbDropdown container="body">
                                    <button type="button" class="btn btn-sm" ngbDropdownToggle data-toggle="dropdown">
                                        <i data-feather="more-vertical"></i>
                                    </button>
                                    <div ngbDropdownMenu>
                                        <button ngbDropdownItem (click)="getMyExpense(item.id)" data-bs-toggle="modal"
                                            data-bs-target="#updateexpense"><i data-feather="edit"
                                                class="mr-50"></i><span>Güncelle</span>
                                        </button>
                                        <button ngbDropdownItem (click)="deletedExpenseId=item.id"
                                            data-bs-toggle="modal" data-bs-target="#deleteexpense"><i
                                                data-feather="trash" class="mr-50"></i><span>Sil</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card-body" style="text-align: center;">
                        {{item.description.length>30? item.description.slice(0,30)+'...':item.description}}
                        <p> <strong>{{item.payment}} TL</strong> </p>
                    </div>
                    <div class="card-footer">
                        <div *ngIf="item.typeId==1" class="badge badge-pill badge-light-success font-small-3 ">Yemek
                        </div>
                        <div *ngIf="item.typeId==2" class="badge badge-pill badge-light-info font-small-3 ">Ulaşım</div>
                        <div *ngIf="item.typeId==3" class="badge badge-pill badge-light-primary font-small-3 ">Konaklama
                        </div>

                        <div class="d-flex align-items-center justify-content-end">{{item.paymentDate|
                            date:'dd-MM-yyyy'}}</div>
                    </div>
                </div>
            </div>
        </swiper>
    </div>
</core-card-snippet>

<div class="content-wrapper container-xxl p-0">
    <div class="content-body">
        <section id="ngx-datatable-kitchen-sink">
            <core-card-snippet>
                <h4 class="card-title">Masrafların Listesi</h4>
                <div class="row">
                    <div class="firstRow mt-1">
                        <div style="margin-left:9px; margin-bottom: 20px;">
                            <div class="col-12">
                                <select class="form-control form-select" [(ngModel)]="basicSelectedOption">
                                    <option value="10">10</option>
                                    <option value="25">25</option>
                                    <option value="50">50</option>
                                    <option value="100">100</option>
                                </select>
                            </div>

                        </div>
                    </div>
                    <div class="search mt-1">
                        <div class="col-12">
                            <input type="text" class="form-control" name="filterText" placeholder="Masraf Arayın.."
                                [(ngModel)]="filterText" [ngModelOptions]="{standalone:true}">
                        </div>
                    </div>

                    <div class="filter mt-1">
                        <div class="col-12" *ngIf="userRole=='Admin' || userRole=='Consultant'">
                            <div class="form-group">
                                <ng-select [multiple]="true" [items]="allUsers" [(ngModel)]="selectedUsers"
                                    placeholder="Kullanıcı Seçiniz " bindLabel="firstName" bindValue="id"
                                    (change)="tableByFiltersId()">
                                    <ng-template ng-label-tmp let-item="item" let-clear="clear">
                                        <span class="ng-value-label">{{ item.firstName }} {{item.lastName}}</span>
                                        <span class="ng-value-icon right" (click)="clear(item)"
                                            aria-hidden="true">×</span>
                                    </ng-template>
                                    <ng-template ng-header-tmp>
                                    </ng-template>
                                </ng-select>
                                <br />
                            </div>
                        </div>
                    </div>
                    <div class="filter mt-1">
                        <div class="col-12" *ngIf="userRole=='Admin' || userRole=='Consultant'">
                            <div class="form-group">
                                <ng-select [multiple]="true" [items]="expensePaymentStatuses"
                                    [(ngModel)]="selectedExpenseStatus" placeholder="Ödeme Durumu Seçiniz"
                                    bindLabel="name" bindValue="id" (change)="tableByFiltersId()">
                                    <ng-template ng-label-tmp let-item="item" let-clear="clear">
                                        <span class="ng-value-label">{{ item.name }}</span>
                                        <span class="ng-value-icon right" (click)="clear(item)"
                                            aria-hidden="true">×</span>
                                    </ng-template>
                                    <ng-template ng-header-tmp>
                                    </ng-template>
                                </ng-select>
                                <br />
                            </div>
                        </div>
                    </div>

                    <div class="filter mt-1" *ngIf="partnerRestriction">
                        <div class="col-12">
                            <div class="form-group">
                                <ng-select [multiple]="true" [items]="partners" [(ngModel)]="selectedPartners"
                                    placeholder="Partner Seçiniz " bindLabel="title" bindValue="id"
                                    (change)="changePartner()">
                                    <ng-template ng-label-tmp let-item="item" let-clear="clear">
                                        <span class="ng-value-label">{{ item.title}}</span>
                                        <span class="ng-value-icon right" (click)="clear(item)"
                                            aria-hidden="true">×</span>
                                    </ng-template>
                                    <ng-template ng-header-tmp>
                                    </ng-template>
                                </ng-select>
                                <br />
                            </div>
                        </div>
                    </div>
                    <div class="filter mt-1">
                        <div class="col-12">
                            <div class="form-group">
                                <ng-select [multiple]="true" [items]="projects" [(ngModel)]="selectedProjects"
                                    placeholder="Proje Seçiniz " bindLabel="title" bindValue="id"
                                    (change)="tableByFiltersId()">
                                    <ng-template ng-label-tmp let-item="item" let-clear="clear">
                                        <span class="ng-value-label">{{ item.title}}</span>
                                        <span class="ng-value-icon right" (click)="clear(item)"
                                            aria-hidden="true">×</span>
                                    </ng-template>
                                    <ng-template ng-header-tmp>
                                    </ng-template>
                                </ng-select>
                                <br />
                            </div>
                        </div>
                    </div>

                    <div class="filter mt-1">
                        <div class="col-12">
                            <div class="form-group">
                                <ng-select [multiple]="true" [items]="expenseTypes" [(ngModel)]="selectedExpenseTypes"
                                    placeholder="Tür Seçiniz " bindLabel="name" bindValue="id"
                                    (change)="tableByFiltersId()">
                                    <ng-template ng-label-tmp let-item="item" let-clear="clear">
                                        <span class="ng-value-label">{{item.name}}</span>
                                        <span class="ng-value-icon right" (click)="clear(item)"
                                            aria-hidden="true">×</span>
                                    </ng-template>
                                    <ng-template ng-header-tmp>
                                    </ng-template>
                                </ng-select>
                                <br />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row  mb-2">
                    <div class="col-3 ml-1">
                        <div class="col-12">
                            <button class="btn btn-primary btn-sm mr-1" data-bs-toggle="modal"
                                data-bs-target="#addexpense" rippleEffect title="Yeni Masraf"
                                (click)="clearFileQueue()">
                                <i data-feather="plus" class="mr-0"></i><span class="d-none d-sm-inline-block"></span>
                            </button>
                            <button class="btn btn-info btn-sm" (click)="exportExcel()" rippleEffect
                                title="Excele Aktar">
                                <i data-feather="file-text"></i>
                            </button>
                        </div>
                    </div>
                    <div id="dateFilter" class="row col-9" style="display: flex;justify-content: end;">
                        <form class="form-inline">
                            <ng-select [clearable]="false" name="selectMonthForFilter" [multiple]="false" [items]="dateFilter"
                            [(ngModel)]="selectedDateFilter" placeholder="Aya Göre Filtrele"
                            bindLabel="name" bindValue="id" (change)="tableByFiltersId()" style="min-width:150px;" class="mr-1">
                            </ng-select>
                            <div class="form-group">
                                <div class="input-group w-0 ">
                                    <input name="datepicker" class="form-control" ngbDatepicker
                                        #datepicker="ngbDatepicker" [autoClose]="'outside'"
                                        (dateSelect)="onDateSelection($event)" [displayMonths]="2"
                                        [dayTemplate]="rangeSelectionDP" outsideDays="hidden"
                                        [startDate]="fromDateExample!" />
                                    <ng-template #rangeSelectionDP let-date let-focused="focused">
                                        <span class="custom-day" type="datetime" [class.focused]="focused"
                                            [class.range]="isRange(date)"
                                            [class.faded]="isHovered(date) || isInside(date)"
                                            (mouseenter)="hoveredDate = date" (mouseleave)="hoveredDate = null">
                                            {{ date.day }}
                                        </span>
                                    </ng-template>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="input-group">
                                    <input #dpFromDate class="form-control" type="date" placeholder="dd-MM-yyyy"
                                        name="dpFromDate"
                                        [ngModel]="checkType(fromDateExample) ? formatter.format(fromDateExample) : fromDateExample"
                                        (ngModelChange)="fromDateExample = $event; tableByDates();" />
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary feather icon-calendar"
                                            (click)="datepicker.toggle()" type="button" rippleEffect></button>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group ml-sm-2 mr-1">
                                <div class="input-group">
                                    <input #dpToDate class="form-control" type="date" placeholder="dd-MM-yyyy"
                                        name="dpToDate"
                                        [ngModel]="checkType(toDateExample) ? formatter.format(toDateExample) : toDateExample"
                                        (ngModelChange)="toDateExample = $event; tableByDates();" />
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary feather icon-calendar"
                                            (click)="datepicker.toggle()" type="button" rippleEffect></button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>

                </div>

                <table class="table table-hover table-bordered" id="table-excel" style="display: none;">
                    <tbody>
                        <tr>
                            <td>Tarih</td>
                            <td>Kullanıcının Adı</td>
                            <td>Açıklama</td>
                            <td>Tutar</td>
                            <td>Masraf Türü</td>
                            <td>Proje</td>
                            <td style="display:none;">İzinli Mi?</td>

                        </tr>
                        <tr *ngFor="let item of allExpenses">
                            <td [innerHTML]="item.paymentDate | date:'yyyy-MM-dd HH:mm:ss'"></td>
                            <td>{{item.userName}}</td>
                            <td>{{item.description }}</td>
                            <td>{{item.payment}} TL</td>
                            <td>{{item.typeName}}</td>
                            <td>{{item.projectTitle}}</td>
                            <td style="display:none;">{{item.userOnLeave==1 ? "Evet" : "Hayır"}}</td>
                        </tr>
                    </tbody>
                </table>
                <ngx-datatable #mytable [rows]="allExpenses |evoExpense:filterText" class="bootstrap core-bootstrap"
                    [rowHeight]="58" [rowClass]="rowClass" [limit]="10" [headerHeight]="40" [footerHeight]="50"
                    [scrollbarH]="true" [limit]="basicSelectedOption" (select)="onSelect($event)">
                    <ngx-datatable-column [width]="50" [sortable]="false" [canAutoResize]="false" [draggable]="false"
                        [resizeable]="false">
                        <ng-template ngx-datatable-header-template let-value="value"
                            let-allRowsSelected="allRowsSelected" let-selectFn="selectFn">
                            <div class="custom-control custom-checkbox">
                                <input type="checkbox" class="custom-control-input" [checked]="allRowsSelected"
                                    (change)="selectFn(!allRowsSelected)" id="headerChkbxRef" />
                                <label class="custom-control-label" for="headerChkbxRef"></label>
                            </div>
                        </ng-template>
                        <ng-template ngx-datatable-cell-template let-row="row" let-rowIndex="rowIndex" let-value="value"
                            let-isSelected="isSelected" let-onCheckboxChangeFn="onCheckboxChangeFn">
                            <div class="custom-control custom-checkbox">
                                <input #mycl type="checkbox" class="custom-control-input" [checked]="isSelected"
                                    (change)="onCheckboxChange($event,rowIndex)" id="rowChkbxRef{{ row.id }}" />
                                <label class="custom-control-label" for="rowChkbxRef{{ row.id }}"></label>
                            </div>
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column name="İşlemler" [width]="120" [sortable]="false">
                        <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
                            <div class="d-flex align-items-center">
                                <div ngbDropdown container="body">
                                    <a ngbDropdownToggle href="javascript:void(0);" class="hide-arrow"
                                        id="dropdownBrowserState" data-toggle="dropdown" aria-haspopup="true"
                                        aria-expanded="false">
                                        <i data-feather="more-vertical" class="text-primary cursor-pointer mr-50"></i>
                                    </a>
                                    <div ngbDropdownMenu class="dropdown-menu-right"
                                        aria-labelledby="dropdownBrowserState">
                                        <button ngbDropdownItem (click)="getMyExpense(row.id)" data-bs-toggle="modal"
                                            data-bs-target="#updateexpense"><i data-feather="edit"
                                                class="mr-50"></i><span>Güncelle</span>
                                        </button>
                                        <button ngbDropdownItem (click)="deletedExpenseId=row.id" data-bs-toggle="modal"
                                            data-bs-target="#deleteexpense">
                                            <i data-feather="trash" class="mr-50"></i><span>Sil</span>
                                        </button>
                                    </div>
                                </div>
                                <button ngbDropdownItem (click)="getMyExpense(row.id)" data-bs-toggle="modal"
                                    data-bs-target="#updateexpense"><i data-feather="edit"
                                        class="text-primary cursor-pointer"></i><span></span>
                                </button>
                            </div>
                        </ng-template>
                    </ngx-datatable-column>

                    <ngx-datatable-column name="Tarihi" prop="paymentDate" [width]="140">
                        <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
                            {{row.paymentDate| date:'dd-MM-yyyy'}}
                        </ng-template>
                    </ngx-datatable-column>

                    <ngx-datatable-column *ngIf="userRole=='Admin'||userRole=='Consultant'" name="Kullanıcının Adı"
                        prop="userName" [width]="200">
                        <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
                            {{row.userName}}
                        </ng-template>
                    </ngx-datatable-column>

                    <ngx-datatable-column name="Açıklama" prop="description" [width]="280">
                        <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
                            {{row.description.length > 30 ? row.description.slice(0, 30) + '...' : row.description}}
                        </ng-template>
                    </ngx-datatable-column>

                    <ngx-datatable-column name="Ödeme" prop="payment" [width]="100">
                        <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
                            {{row.payment}} TL
                        </ng-template>
                    </ngx-datatable-column>

                    <ngx-datatable-column name="Türü" prop="type" [width]="130">
                        <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
                            <div *ngIf="row.typeId==1" class="badge badge-pill badge-light-success font-small-3 ">Yemek
                            </div>
                            <div *ngIf="row.typeId==2" class="badge badge-pill badge-light-info font-small-3 ">Ulaşım
                            </div>
                            <div *ngIf="row.typeId==3" class="badge badge-pill badge-light-primary font-small-3 ">
                                Konaklama</div>
                        </ng-template>
                    </ngx-datatable-column>

                    <ngx-datatable-column name="Proje" prop="project" [width]="220">
                        <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
                            {{row.projectTitle}}
                        </ng-template>
                    </ngx-datatable-column>

                    <ngx-datatable-column *ngIf="userRole=='Admin'||userRole=='Consultant'" name="Ödeme Durumu"
                        prop="userName" [width]="180">
                        <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
                            <div class="align-items-center">
                                <div [class]="paymentStateClass(row)">{{row.paymentStatusName}}</div>
                            </div>
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column name="Reddedilme Nedeni" prop="userName" [width]="200">
                        <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
                            <div *ngIf="row.causeOfRejection !='null'" class="align-items-center">
                                <div>{{row.causeOfRejection}}</div>
                            </div>

                        </ng-template>
                    </ngx-datatable-column>


                    <ngx-datatable-column name="İşlemler" [width]="120" [sortable]="false">
                        <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
                            <div class="d-flex align-items-center">
                                <div ngbDropdown container="body">
                                    <a ngbDropdownToggle href="javascript:void(0);" class="hide-arrow"
                                        id="dropdownBrowserState" data-toggle="dropdown" aria-haspopup="true"
                                        aria-expanded="false">
                                        <i data-feather="more-vertical" class="text-primary cursor-pointer mr-50"></i>
                                    </a>
                                    <div ngbDropdownMenu class="dropdown-menu-right"
                                        aria-labelledby="dropdownBrowserState">
                                        <button ngbDropdownItem (click)="getMyExpense(row.id)" data-bs-toggle="modal"
                                            data-bs-target="#updateexpense"><i data-feather="edit"
                                                class="mr-50"></i><span>Güncelle</span>
                                        </button>
                                        <button ngbDropdownItem (click)="deletedExpenseId=row.id" data-bs-toggle="modal"
                                            data-bs-target="#deleteexpense">
                                            <i data-feather="trash" class="mr-50"></i><span>Sil</span>
                                        </button>
                                    </div>
                                </div>
                                <button ngbDropdownItem (click)="getMyExpense(row.id)" data-bs-toggle="modal"
                                    data-bs-target="#updateexpense"><i data-feather="edit"
                                        class="text-primary cursor-pointer"></i><span></span>
                                </button>
                            </div>
                        </ng-template>
                    </ngx-datatable-column>
                </ngx-datatable>
                <div class="row">
                    <div class="col-10"></div>
                    <div class="col-2 mb-3 mt-2">
                        Toplam: <div
                            class="badge badge-pill badge-danger font-small-3 font-weight-bold line-height-2 mb-25">
                            {{allExpensesTotal}} TL
                        </div>

                    </div>
                </div>
            </core-card-snippet>
            <div *ngIf="userRole=='Admin'" class="col-md-12 my-3">
                <div class="row">
                    <div class="col-9"></div>
                    <div style="display: flex;justify-content: end;" class="col-3">

                        <form>
                            <select *ngIf="selectedExpenses.length > 0 else availablebtn"
                                class="form-control btn btn-success " name="selecedtPaymentStatusId"
                                [(ngModel)]="selecedtPaymentStatusId" (change)="openModal()">
                                <option value="0" selected> Masrafın Durumunu Seçiniz </option>
                                <option value="2"><span class="bullet bullet-sm bullet-primary">Ödendi</span></option>
                                <option value="1"><span class="bullet bullet-sm bullet-warning">Ödenmedi</span>
                                <option value="3"><span class="bullet bullet-sm bullet-primary">Onaylandı</span>
                                </option>
                                <option value="4"><span class="bullet bullet-sm bullet-warning">Reddedildi</span>
                                </option>
                            </select>
                            <!-- resetselectbox => amacı kullanıcı işlem yapmazsa select kutusunu resetlemek açılan modaldaki kapatma tuşuna bağlı bir fonksiyon tarafından tetikleniyor. -->
                            <input type="reset" value="ödendi" style="display: none;" id="resetselectbox">
                        </form>
                        <ng-template #availablebtn>
                            <select disabled class="form-control btn btn-success " name="selecedtPaymentStatusId"
                                [(ngModel)]="selecedtPaymentStatusId" (change)="openModal()">
                                <option value="0" selected> Masrafın Durumunu Seçiniz </option>
                                <option value="2"><span class="bullet bullet-sm bullet-primary">Ödendi</span></option>
                                <option value="1"><span class="bullet bullet-sm bullet-warning">Ödenmedi</span>
                                </option>
                            </select>
                        </ng-template>
                    </div>
                </div>
            </div>
            <div class="modal fade" id="addexpense" tabindex="-1" aria-labelledby="staticBackdropLabel"
                aria-hidden="true">
                <div class="modal-dialog modal-lg">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h1 class="modal-title fs-5" id="staticBackdropLabel">Masraf Bilgisini Ekle</h1>
                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"><i
                                    data-feather="x-circle" class="mr-50"></i>Çıkış</button>
                        </div>
                        <div class="modal-body">
                            <form class="" #AddExpense="ngForm">
                                <div class="modal-body">
                                    <div class="form-group">
                                        <div class="row mb-2">
                                            <label for="name-vertical">Tarih Seçiniz</label>
                                            <input type="datetime-local" name="txtPaymentDate" #txtPaymentDate
                                                class="form-control" [formControl]="startDateControl" required>
                                            <small class="text-danger"
                                                *ngIf="txtPaymentDate.invalid&& txtPaymentDate.touched">Tarih kısmı boş
                                                bırakılamaz!</small>
                                        </div>
                                        <div class="row mb-2" *ngIf="userRole=='Admin'|| userRole=='Consultant'">
                                            <label for="name-vertical">Kullanıcı Seçiniz</label>
                                            <select name="slcUser"
                                                class="form-control form-select" required #slcUser="ngModel"
                                                [(ngModel)]="user.id">
                                                <option value="0">Kullanıcı Seçiniz</option>
                                                <option value="{{myId}}">{{userName}}</option>
                                                <option *ngFor="let item of users" value="{{item.id}}">
                                                    {{item.firstName}} {{item.lastName}}
                                                </option>
                                            </select>
                                            <small class="text-danger"
                                                *ngIf="slcUser.invalid&& slcUser.touched">Kullanıcı boş
                                                bırakılamaz!</small>
                                        </div>
                                        <div class="row mb-2">
                                            <label for="name-vertical">Cari Seçiniz</label>
                                            <select class="form-control" required name="slcPartner"
                                                [(ngModel)]="partner.id" (ngModelChange)="changeProjectWithPartner()">
                                                <option value="0"> Cari Seçiniz </option>
                                                <option *ngFor="let item of partners" value="{{item.id}}">{{item.name}}
                                                </option>
                                            </select>
                                        </div>
                                        <div class="row mb-2">
                                            <label for="name-vertical">Proje Seçiniz </label>
                                            <select name="slcProject" class="form-control form-select" required
                                                #slcProject="ngModel" [(ngModel)]="project.id">
                                                <option value="0" selected> Proje Seçiniz </option>
                                                <option *ngFor="let item of projects" value="{{item.id}}">
                                                    {{item.title}}
                                                </option>
                                            </select>
                                            <small class="text-danger"
                                                *ngIf="slcProject.invalid&& slcProject.touched">Proje boş
                                                bırakılamaz!</small>
                                        </div>



                                        <div class="row mb-2">
                                            <label for="name-vertical">Masraf Türü Seçiniz</label>
                                            <select name="slcType" class="form-control form-select" required
                                                #slcType="ngModel" [(ngModel)]="expenseType.id">
                                                <option *ngFor="let item of expenseTypes" value="{{item.id}}">
                                                    {{item.name}}
                                                </option>
                                            </select>
                                            <small class="text-danger" *ngIf="slcType.invalid&& slcType.touched">Tür boş
                                                bırakılamaz!</small>
                                        </div>

                                        <div class="row mb-2">
                                            <label for="name-vertical">Açıklama giriniz</label>

                                            <textarea name="txtAddDescription" required class="form-control"
                                                #txtAddDescription="ngModel" ngModel></textarea>
                                            <small class="text-danger"
                                                *ngIf="txtAddDescription.invalid&& txtAddDescription.touched">Açıklama
                                                boş bırakılamaz!</small>
                                        </div>
                                        <div class="row mb-2">
                                            <label for="name-vertical">Tutar Giriniz</label>
                                            <input type="number" class="form-control" name="txtAddPayment" required
                                                #txtAddPayment="ngModel" ngModel>
                                            <small class="text-danger"
                                                *ngIf="txtAddPayment.invalid&& txtAddPayment.touched">Tutar kısmı boş
                                                bırakılamaz!</small>
                                        </div>
                                        <div style="margin: 50px 0px 20px 0px;">
                                            <fieldset class="form-group">
                                                <label for="file-upload-multiple">Dosyalar</label>
                                                <div class="custom-file">
                                                    <input class="custom-file-input" type="file"
                                                        (change)="getDocuments($event)" #documents
                                                        id="file-upload-multiple" ng2FileSelect [uploader]="uploader"
                                                        multiple />
                                                    <label class="custom-file-label" for="file-upload-multiple">Dosya
                                                        Seçiniz</label>
                                                </div>
                                            </fieldset>
                                        </div>
                                        <div class="col-lg-12 overflow-auto">
                                            <div class="d-flex justify-content-between mb-1">
                                                <p>Seçilen Dosya Sayısı: {{ uploader?.queue?.length }}</p>
                                            </div>

                                            <table class="table">
                                                <thead>
                                                    <tr>
                                                        <th width="50%">Adı</th>
                                                        <th>Boyutu</th>
                                                        <th>Aşaması</th>
                                                        <th>Durumu</th>
                                                        <th>İşlemler</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let item of uploader.queue">
                                                        <td>
                                                            <strong>{{ item?.file?.name }}</strong>
                                                        </td>
                                                        <td *ngIf="uploader.options.isHTML5" nowrap>
                                                            {{ item?.file?.size / 1024 / 1024 | number: '.2' }} MB
                                                        </td>
                                                        <td *ngIf="uploader.options.isHTML5">
                                                            <ngb-progressbar type="primary"
                                                                [value]="100"></ngb-progressbar>
                                                        </td>
                                                        <td class="text-center">
                                                            <span><i data-feather="check"></i></span>
                                                        </td>
                                                        <td nowrap>
                                                            <!-- <button type="button" class="btn btn-raised btn-outline-success btn-sm mr-1"
                                                      (click)="downloadImage(item.file.name)"
                                                      rippleEffect>
                                                      <span data-feather="upload"></span>
                                                    </button> -->
                                                            <button type="button"
                                                                class="btn btn-raised btn-outline-primary btn-sm mr-1"
                                                                data-bs-toggle="modal"
                                                                data-bs-target="#showpriviewimage"
                                                                (click)="previewImage(item)" rippleEffect>
                                                                <i data-feather="eye"></i>
                                                            </button>

                                                            <button type="button"
                                                                class="btn btn-raised btn-outline-danger btn-sm "
                                                                (click)="item.remove()" rippleEffect>
                                                                <i data-feather="trash"></i>
                                                            </button>
                                                        </td>
                                                    </tr>
                                                </tbody>

                                            </table>
                                            <div id="">
                                                <p class="mt-1">Dosyanın Aşaması:</p>
                                                <ngb-progressbar class="mb-1" type="primary"
                                                    [value]="uploader.progress"></ngb-progressbar>
                                                <!-- <button type="button" class="btn mr-1 btn-primary mb-1 mb-sm-0" (click)="uploader.uploadAll()"
                                                [disabled]="!uploader.getNotUploadedItems().length" rippleEffect>
                                                <span data-feather="upload" class="mr-25"></span> Hepsini Yükle
                                              </button> -->
                                                <button type="button" class="btn btn-outline-danger mb-1 mb-sm-0"
                                                    (click)="uploader.clearQueue()" [disabled]="!uploader.queue.length"
                                                    rippleEffect>
                                                    <span data-feather="trash" class="mr-25"></span>Hepsini Sil
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="modal-footer">
                                    <div class="modal-footer">
                                        <div *ngIf="AddExpense.valid; else emptyAddExpenseButton ">
                                            <button type="button" class="btn btn-success" data-bs-dismiss="modal"
                                                (click)="add(txtAddPayment.value,txtAddDescription.value,txtPaymentDate.value)"><i
                                                    data-feather="check" class="mr-50"></i>Kaydet</button>
                                        </div>
                                        <ng-template #emptyAddExpenseButton>
                                            <button type="submit" rippleEffect class="btn btn-success mr-1"
                                                disabled>Kaydet</button>
                                        </ng-template>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal fade" id="updateexpense" tabindex="-1" aria-labelledby="staticBackdropLabel"
                aria-hidden="true">
                <div class="modal-dialog modal-lg">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h1 class="modal-title fs-5" id="staticBackdropLabel">Masraf Bilgisini Güncelle</h1>
                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"><i
                                    data-feather="x-circle" class="mr-50"></i>Çıkış</button>
                        </div>
                        <div class="modal-body">
                            <form class="" #UpdateExpense="ngForm">
                                <div class="modal-body">
                                    <div class="form-group">
                                        <div class="row mb-2">
                                            <label for="name-vertical">Tarih Seçiniz</label>
                                            <input type="datetime-local" name="txtPaymentDate"
                                                [(ngModel)]="expense.paymentDate" #txtPaymentDate class="form-control"
                                                [formControl]="startDateControl" required>
                                            <small class="text-danger"
                                                *ngIf="txtPaymentDate.invalid&& txtPaymentDate.touched">Tarih kısmı boş
                                                bırakılamaz!</small>
                                        </div>
                                        <div class="row mb-2" *ngIf="userRole=='Admin'|| userRole=='Consultant'">
                                            <label for="name-vertical">Kullanıcı Seçiniz</label>
                                            <select name="slcUser" class="form-control form-select"
                                                required #slcUser="ngModel"
                                                [(ngModel)]="expense.userId">
                                                <option value="{{myId}}">{{userName}}</option>
                                                <option *ngFor="let item of users" value="{{item.id}}">
                                                    {{item.firstName}} {{item.lastName}}
                                                </option>
                                            </select>

                                            <small class="text-danger"
                                                *ngIf="slcUser.invalid&& slcUser.touched">Kullanıcı boş
                                                bırakılamaz!</small>
                                        </div>
                                        <div class="row mb-2">
                                            <label for="name-vertical">Cari Seçiniz</label>
                                            <select class="form-control form-select" required name="slcPartner"
                                                #slcPartner = "ngModel"
                                                [(ngModel)]="partner.id" (ngModelChange)="changeProjectWithPartnerUpdate()">
                                                <option *ngFor="let item of partners" value="{{item.id}}">{{item.name}}
                                                </option>
                                            </select>
                                        </div>
                                        <div class="row mb-2">
                                            <label for="name-vertical">Proje Seçiniz </label>
                                            <select name="slcProject" class="form-control form-select" required
                                                #slcProject="ngModel" [(ngModel)]="expense.projectId">
                                                <option value="0">Proje Seçiniz</option>
                                                <option *ngFor="let item of projects" value="{{item.id}}">
                                                    {{item.title}}
                                                </option>
                                            </select>
                                            <small class="text-danger"
                                                *ngIf="slcProject.invalid&& slcProject.touched">Proje boş
                                                bırakılamaz!</small>
                                        </div>


                                        <div class="row mb-2" *ngIf="userRole=='Admin'">
                                            <label for="name-vertical">Ödeme Durumu Seçiniz</label>
                                            <select name="slcpaymentstatus" class="form-control form-select" required
                                                #slcpaymentstatus="ngModel" [(ngModel)]="expense.paymentStatusId">
                                                <option *ngFor="let item of expensePaymentStatuses" value="{{item.id}}">
                                                    {{item.name}}
                                                </option>
                                            </select>
                                            <small class="text-danger"
                                                *ngIf="slcpaymentstatus.invalid&& slcpaymentstatus.touched">Ödeme Durum
                                                Bilgisi Alanı Boş Bırakılamaz!</small>
                                        </div>

                                        <div class="row mb-2" *ngIf="expense.paymentStatusId == 4">
                                            <label for="name-vertical">Reddetme Nedeni Giriniz</label>

                                            <textarea name="txtUpdateCauseOfRejection" required class="form-control"
                                                #txtUpdateCauseOfRejection="ngModel"
                                                [(ngModel)]="expense.causeOfRejection"></textarea>
                                            <small class="text-danger"
                                                *ngIf="txtUpdateCauseOfRejection.invalid&& txtUpdateCauseOfRejection.touched">Açıklama
                                                boş bırakılamaz!</small>
                                        </div>

                                        <div class="row mb-2">
                                            <label for="name-vertical">Masraf Türü Seçiniz</label>
                                            <select name="slcType" class="form-control form-select" required
                                                #slcType="ngModel" [(ngModel)]="expense.typeId">
                                                <option *ngFor="let item of expenseTypes" value="{{item.id}}">
                                                    {{item.name}}
                                                </option>
                                            </select>
                                            <small class="text-danger" *ngIf="slcType.invalid&& slcType.touched">Tür boş
                                                bırakılamaz!</small>
                                        </div>

                                        <div class="row mb-2">
                                            <label for="name-vertical">Açıklama giriniz</label>

                                            <textarea name="txtUpdateDescription" required class="form-control"
                                                #txtUpdateDescription="ngModel"
                                                [(ngModel)]="expense.description"></textarea>
                                            <small class="text-danger"
                                                *ngIf="txtUpdateDescription.invalid&& txtUpdateDescription.touched">Açıklama
                                                boş bırakılamaz!</small>
                                        </div>

                                        <div class="row mb-2">
                                            <label for="name-vertical">Tutar Giriniz</label>
                                            <input type="number" class="form-control" name="txtUpdatePayment" required
                                                [(ngModel)]="expense.payment" #txtUpdatePayment="ngModel">
                                            <small class="text-danger"
                                                *ngIf="txtUpdatePayment.invalid&& txtUpdatePayment.touched">Tutar kısmı
                                                boş bırakılamaz!</small>
                                        </div>

                                        <div style="margin: 50px 0px 20px 0px;">
                                            <fieldset class="form-group">
                                                <label for="file-upload-multiple">Dosyalar</label>
                                                <div class="custom-file">
                                                    <input class="custom-file-input" type="file"
                                                        (change)="getDocuments($event)" #documents
                                                        id="file-upload-multiple" ng2FileSelect [uploader]="uploader"
                                                        multiple />
                                                    <label class="custom-file-label" for="file-upload-multiple">Dosya
                                                        Seçiniz</label>
                                                </div>
                                            </fieldset>
                                        </div>
                                        <div class="col-lg-12 overflow-auto">
                                            <div class="d-flex justify-content-between mb-1">
                                                <p>Seçilen Dosya Sayısı: {{ uploader?.queue?.length }}</p>
                                            </div>

                                            <table class="table">
                                                <thead>
                                                    <tr>
                                                        <th width="50%">Adı</th>
                                                        <th>Boyutu</th>
                                                        <th>Aşaması</th>
                                                        <th>Durumu</th>
                                                        <th>İşlemler</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let item of uploader.queue">
                                                        <td>
                                                            <strong>{{ item?.file?.name }}</strong>
                                                        </td>
                                                        <td *ngIf="uploader.options.isHTML5" nowrap>
                                                            {{ item?.file?.size / 1024 / 1024 | number: '.2' }} MB
                                                        </td>
                                                        <td *ngIf="uploader.options.isHTML5">
                                                            <ngb-progressbar type="primary"
                                                                [value]="100"></ngb-progressbar>
                                                        </td>
                                                        <td class="text-center">
                                                            <span><i data-feather="check"></i></span>
                                                        </td>
                                                        <td nowrap>
                                                            <button type="button"
                                                                class="btn btn-raised btn-outline-success btn-sm mr-1"
                                                                (click)="downloadImage(item.file.name)" rippleEffect>
                                                                <span data-feather="upload"></span>
                                                            </button>
                                                            <button type="button"
                                                                class="btn btn-raised btn-outline-primary btn-sm mr-1"
                                                                data-bs-toggle="modal"
                                                                data-bs-target="#showpriviewimage"
                                                                (click)="previewImage(item)" rippleEffect>
                                                                <i data-feather="eye"></i>
                                                            </button>

                                                            <button type="button"
                                                                class="btn btn-raised btn-outline-danger btn-sm "
                                                                (click)="item.remove()" rippleEffect>
                                                                <i data-feather="trash"></i>
                                                            </button>
                                                        </td>
                                                    </tr>
                                                </tbody>

                                            </table>
                                            <div id="">
                                                <p class="mt-1">Dosyanın Aşaması:</p>
                                                <ngb-progressbar class="mb-1" type="primary"
                                                    [value]="uploader.progress"></ngb-progressbar>
                                                <!-- <button type="button" class="btn mr-1 btn-primary mb-1 mb-sm-0" (click)="uploader.uploadAll()"
                                                [disabled]="!uploader.getNotUploadedItems().length" rippleEffect>
                                                <span data-feather="upload" class="mr-25"></span> Hepsini Yükle
                                              </button> -->
                                                <button type="button" class="btn btn-outline-danger mb-1 mb-sm-0"
                                                    (click)="uploader.clearQueue()" [disabled]="!uploader.queue.length"
                                                    rippleEffect>
                                                    <span data-feather="trash" class="mr-25"></span>Hepsini Sil
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="modal-footer">
                                    <div class="modal-footer">
                                        <div *ngIf="UpdateExpense.valid; else emptyUpdateExpenseButton ">
                                            <button type="button" class="btn btn-success" data-bs-dismiss="modal"
                                                (click)="update()"><i data-feather="check"
                                                    class="mr-50"></i>Kaydet</button>
                                        </div>
                                        <ng-template #emptyUpdateExpenseButton>
                                            <button type="submit" rippleEffect class="btn btn-success mr-1"
                                                disabled>Kaydet</button>
                                        </ng-template>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal fade" id="deleteexpense" tabindex="-1" aria-labelledby="staticBackdropLabel"
                aria-hidden="true">
                <div class="modal-dialog modal-lg">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h1 class="modal-title fs-5" id="staticBackdropLabel">Masraf Bilgisini Sil</h1>
                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"><i
                                    data-feather="x-circle" class="mr-50"></i>Çıkış</button>
                        </div>
                        <div class="modal-body">
                            Kaydı Silmek İstediğinize Emin Misiniz?
                        </div>
                        <div class="modal-footer">
                            <div>
                                <button type="button" class="btn btn-danger" data-bs-dismiss="modal"
                                    (click)="delete(deletedExpenseId)"><i data-feather="trash"
                                        class="mr-50"></i>Sil</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal fade" id="updatemultiple" tabindex="-1" aria-labelledby="staticBackdropLabel"
                aria-hidden="true">
                <div class="modal-dialog modal-lg">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h1 class="modal-title fs-5" id="staticBackdropLabel">Masraf Bilgisini Güncelle</h1>
                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"><i
                                    data-feather="x-circle" class="mr-50"></i>Çıkış</button>
                        </div>
                        <div class="modal-body">
                            Güncellemek istediğinize Emin Misiniz?
                        </div>
                        <div class="modal-footer">
                            <div>
                                <button type="button" class="btn btn-warning" data-bs-dismiss="modal"
                                    (click)="updateMultiple()">Güncelle</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</div>

<button id="openmodal" data-bs-toggle="modal" data-bs-target="#updatemultiple" style="display: none;"></button>