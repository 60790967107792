import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { PurchaseEnquiry } from '../models/purchaseenquiry';
import { PurchaseEnquiryFile } from '../models/purchaseenquiryfile';
import { PurchaseEnquiryList } from '../models/purchaseenquirylist';

@Injectable({
  providedIn: 'root'
})
export class EvoPurchaseEnquiryService {

  private baseApiUrl=environment.apiUrl;

  constructor(private httpClient:HttpClient) { }

  getList():Observable<PurchaseEnquiryList[]>{
    return this.httpClient.get<PurchaseEnquiryList[]>(this.baseApiUrl+'GetPurchaseEnquiryList');
  }
  getListByUserId(userId:number):Observable<PurchaseEnquiryList[]>{
    return this.httpClient.get<PurchaseEnquiryList[]>(this.baseApiUrl+'GetPurchaseEnquiryListByUserId/'+userId);
  }
  get (id: number):Observable <PurchaseEnquiry> {
    return this.httpClient.get<PurchaseEnquiry>(this.baseApiUrl+'GetPurchaseEnquiry/'+id);
  }
  add(formData:FormData):Observable<PurchaseEnquiry>{
    return this.httpClient.post<PurchaseEnquiry>(this.baseApiUrl+'AddPurchaseEnquiry/',formData);
  }

  update(formData:FormData):Observable<PurchaseEnquiry>{
    return this.httpClient.post<PurchaseEnquiry>(this.baseApiUrl+"UpdatePurchaseEnquiry/",formData);
  }
  delete(id:number):Observable<PurchaseEnquiry>{
    return this.httpClient.post<PurchaseEnquiry>(this.baseApiUrl+"DeletePurchaseEnquiry/"+id,id);
  }

  downloadDocument(fileInfo:PurchaseEnquiryFile){
    return this.httpClient.post(this.baseApiUrl+'DownloadPurchaseDocumentForm',fileInfo,{observe:'response',responseType: 'blob'});
  }
}
