import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DecodeService } from 'app/evo/login/service/decode.service';
import { PurchaseEnquiry } from 'app/evo/models/purchaseenquiry';
import { PurchaseEnquiryList } from 'app/evo/models/purchaseenquirylist';
import { PurchaseItem } from 'app/evo/models/purchaseitem';
import { User } from 'app/evo/models/user';
import { ErrorService } from 'app/evo/services/error.service';
import { EvoUserService } from 'app/evo/services/evo-user.service';
import { HelperService } from 'app/evo/services/helper.service';
import { InformationService } from 'app/evo/services/information.service';
import { EvoPurchaseEnquiryService } from 'app/evo/services/purchaseenquiry.service';
import { EvoPurchaseItemService } from 'app/evo/services/purchaseitem.service';
import { environment } from 'environments/environment';
import { FileItem, FileUploader } from 'ng2-file-upload';
import { ConfirmationService, ConfirmEventType } from 'primeng/api';
import { Observable, of } from 'rxjs';
import * as _ from 'lodash';
import { PurchaseEnquiryFile } from 'app/evo/models/purchaseenquiryfile';

const URL =  environment.apiUrl + 'UploadPurchaseDocumentForm';

@Component({
  selector: 'app-evo-purchase-enquiry-detail',
  templateUrl: './evo-purchase-enquiry-detail.component.html',
  styleUrls: ['./evo-purchase-enquiry-detail.component.scss'
  ],
  providers:[ConfirmationService]
})
export class EvoPurchaseEnquiryDetailComponent implements OnInit {

  constructor(
    private purchaseEnquiryService:EvoPurchaseEnquiryService,
    private purchaseItemService:EvoPurchaseItemService,
    private errorService: ErrorService,
    private helperService: HelperService,
    private informationService: InformationService,
    private decodeService: DecodeService,
    private confirmationService:ConfirmationService,
    private router:Router,
    private route: ActivatedRoute,
    private userService:EvoUserService,

  ) { 


  }

  userRole: string = "";
  myId:number=0;
  purchaseEnquiry:PurchaseEnquiryList = new PurchaseEnquiryList();
  purchaseItemList:PurchaseItem[]=[];
  filteredPurchaseItemList:PurchaseItem[]=[];

  purchaseItem:PurchaseItem=new PurchaseItem();

  private themeLink: HTMLLinkElement;
  private primengLink: HTMLLinkElement;
  private primeiconsLink: HTMLLinkElement;

  action:string="Ekle";
  public uploader: FileUploader = null;
  documents:any[]=[];

  purchaseEnquiryId:number;
  users:User[]=[];
  processPending:boolean=false;
  approvalStatuses:any[]=[
    {id:1,label:"Onay Bekleniyor"},
    {id:2,label:"Onaylandı"},
    {id:3,label:"Reddedildi"}]

  selectedApprovalStatus:any[]=[];
  filterText:string;
  page:number=1;

  savedPurchaseEnquiryInfo :PurchaseEnquiryList = new PurchaseEnquiryList();
  savedFileInfo: any[] = [];

  ngOnDestroy(): void {
    this.unloadPrimeNGStyles();
  }

  ngOnInit(): void {
    this.loadPrimeNGStyles();

    this.purchaseEnquiryId = this.route.snapshot.params['purchaseEnquiryId'];
    this.userRole = this.decodeService.getRole();
    this.myId = this.decodeService.getUserId();
    this.requiredData();
    this.list();
    this.configureFileUploader();

  }

  requiredData(){
    this.userService.getActiveUserList().subscribe((res:any)=>{
      this.users = res.data.map(item => {
        return {
          ...item,  // Spread operator to include all existing properties of the item
          fullName: item.firstName + " " + item.lastName // Adding the fullName property
        };
      });       
    })
  }


  list() {

    this.purchaseEnquiryService.get(this.purchaseEnquiryId).subscribe((res:any)=>{
        this.purchaseEnquiry = res.data;
        this.savedPurchaseEnquiryInfo = JSON.parse(JSON.stringify(this.purchaseEnquiry));  // Deep copy

        this.savedPurchaseEnquiryInfo = {...this.purchaseEnquiry};
        let allFiles:Array<FileItem>=[];
        const uniqueFiles = new Set<string>();
        this.uploader.queue = [];

        this.purchaseEnquiry.documents.forEach((item:any)=>{
          let file = new File([item.content], item.fileName, {type: item.mimeType});

          const fileIdentifier = `${file.name}-${file.size}-${file.type}`;

          if (!uniqueFiles.has(fileIdentifier)) {
            uniqueFiles.add(fileIdentifier);
        
            const fileItem = new FileItem(this.uploader, file, {});
            this.uploader.queue.push(fileItem);
          }
        
        })
        
        this.savedFileInfo = _.cloneDeepWith(this.uploader.queue, (value) => {
          if (value instanceof FileUploader) {
            return;  // Skip the circular reference
          }
        });        
        
        this.informationService.list("Satın Alma Bilgisi Başarıyla Getirildi.");
    })

    this.purchaseItemService.getListByPurchaseEnquiryId(this.purchaseEnquiryId).subscribe((res:any)=>{
        this.purchaseItemList = res.data;
        this.filteredPurchaseItemList=res.data;
        this.filterTable();
        this.informationService.list("Satın Alınacak Ürünler Listesi Başarıyla Getirildi.");

    })


  }


  getItem(id: number) {
    this.purchaseItemService.get(id).subscribe((res:any)=>{
        this.purchaseItem = res.data;
        console.log(this.purchaseItem);
    })
  }

  clearItem(){
    this.purchaseItem=new PurchaseItem();
  }

  savePurchaseItem(){

    this.processPending=true;
    this.purchaseItem.purchaseEnquiryId = this.purchaseEnquiryId
    if(this.purchaseItem.orderDate == ''){
      this.purchaseItem.orderDate = null;
    }
    if(this.purchaseItem.dispatchDate == ''){
      this.purchaseItem.dispatchDate = null;
    }
    if(this.purchaseItem.invoiceDate == ''){
      this.purchaseItem.invoiceDate = null;
    }
    if(this.purchaseItem.id==0){
        this.addPurchaseItem();
    }
    else{
        this.updatePurchaseItem();
    }
  }

  updatePurchaseEnquiry(){
    
    let formData = new FormData()
    formData.append("Id", this.purchaseEnquiry.id.toString());
    formData.append("Description", this.purchaseEnquiry.description.toString());
    formData.append("RequesterId", this.purchaseEnquiry.requesterId.toString());
    formData.append("PurchaseDate", this.purchaseEnquiry.purchaseDate.toString());
    formData.append("PurchaseDeadline", this.purchaseEnquiry.purchaseDeadline.toString());
    formData.append("PurchaseOrderNo", this.purchaseEnquiry.purchaseOrderNo.toString());
    for (let i = 0; i < this.uploader.queue.length; i++) {
      formData.append('Documents', this.uploader.queue[i]._file)
    }

    this.purchaseEnquiryService.update(formData).subscribe((res:any)=>{
        this.informationService.update("Satın alma bilgisi güncellendi.");

    },(err)=>{
        this.errorService.errorHandler(err);
    },()=>{
        setTimeout(()=>{this.list()},150);
    })
  }

  addPurchaseItem(){
    this.purchaseItemService.add(this.purchaseItem).subscribe((res:any)=>{
        this.informationService.add("Satın alınacak ürün eklendi.");

    },(err)=>{
        this.errorService.errorHandler(err);
        this.processPending=false;

    },()=>{
        setTimeout(()=>{
          this.list();
          this.processPending=false;
          this.clearItem();
        },150);
    })
  }

  updatePurchaseItem(){
    this.purchaseItemService.update(this.purchaseItem).subscribe((res:any)=>{
        this.informationService.update("Satın alınacak ürün güncellendi.");

    },(err)=>{
        this.errorService.errorHandler(err);
        this.processPending=false;

    },()=>{
        setTimeout(()=>{
          this.list();
          this.processPending=false;
        },150);
    })
  }

  loadPrimeNGStyles() {
    const head = document.getElementsByTagName('head')[0];

    // PrimeNG theme
    this.themeLink = document.createElement('link');
    this.themeLink.rel = 'stylesheet';
    this.themeLink.href = 'https://cdn.jsdelivr.net/npm/primeng/resources/themes/lara-light-blue/theme.css';
    head.appendChild(this.themeLink);

    // PrimeNG core styles
    this.primengLink = document.createElement('link');
    this.primengLink.rel = 'stylesheet';
    this.primengLink.href = 'https://cdn.jsdelivr.net/npm/primeng/resources/primeng.min.css';
    head.appendChild(this.primengLink);

    // PrimeIcons
    this.primeiconsLink = document.createElement('link');
    this.primeiconsLink.rel = 'stylesheet';
    this.primeiconsLink.href = 'https://cdn.jsdelivr.net/npm/primeicons/primeicons.css';
    head.appendChild(this.primeiconsLink);
  }

  unloadPrimeNGStyles() {
    if (this.themeLink) {
      this.themeLink.remove();
    }
    if (this.primengLink) {
      this.primengLink.remove();
    }
    if (this.primeiconsLink) {
      this.primeiconsLink.remove();
    }
  }

  delete(id: number) {

    this.confirmationService.confirm({
      message: 'Bu satın alınacak ürünü silmek istediğinize emin misiniz?',
      header: 'Satın Alınacak Ürün Bilgisi Silme',
      acceptButtonStyleClass: 'btn btn-success hide-duplicated-icon',
      rejectButtonStyleClass: 'btn btn-secondary mr-2',
      acceptLabel: 'Evet',
      rejectLabel: 'Hayır',
      accept: () => {
        this.purchaseItemService.delete(id).subscribe((res:any)=>{
            this.informationService.delete("Satın alınacak ürün başarıyla silindi.")
        },(err:any)=>{
            this.errorService.errorHandler(err);
            this.processPending=false;

        },()=>{
            setTimeout(()=>{
                this.list()
            },150);
        })

      },
      reject: (type: ConfirmEventType) => {
        switch(type) {
          case ConfirmEventType.REJECT:
            break;
          case ConfirmEventType.CANCEL:
            break;      
        }
      }
    });

  }

  exportExcel() {
    let element = document.getElementById("excel-table");
    let title = "Satın Alınacak Ürünler";
    this.helperService.exportExcel(element, title);
  }

  getDocuments(event: any) {
    this.documents.push(this.uploader.queue);
  }

  downloadImage(name: string) {

    var fileInfo = new PurchaseEnquiryFile();
    fileInfo.purchaseEnquiryId = this.purchaseEnquiry.id
    fileInfo.name = name;

    this.purchaseEnquiryService.downloadDocument(fileInfo).subscribe((data:any) => {
      const blob = new Blob([data.body as BlobPart], { type: data.body?.type });

      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download =name;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);


    },(error:any)=>{
    });
  }

  configureFileUploader(){
    this.uploader = new FileUploader({
      url: URL,
      isHTML5: true,
      
    });
    this.uploader.onBeforeUploadItem = (item) => {
      item.withCredentials = false;
    }
  }

  selectedFileUrl: string;

  previewImage(item: any) {
    const reader = new FileReader();
    reader.onload = (event: any) => {
      this.selectedFileUrl = event.target.result;
    };
    reader.readAsDataURL(item.file);
  }
  
  filterTable(){
    let filteredTable = this.purchaseItemList;

    if(this.selectedApprovalStatus.length>0){
      filteredTable = filteredTable.filter(x=>this.selectedApprovalStatus.includes(x.approvalStatus));

    }

    this.filteredPurchaseItemList = filteredTable;
  }


  checkIfItemsAreIdentical() {
    // Use lodash's isEqual for deep comparison
    if (_.isEqual(this.savedPurchaseEnquiryInfo, this.purchaseEnquiry) && _.isEqual(this.savedFileInfo, this.uploader.queue)) {
      return true;
    } else {
      return false;
    }
  }
  canDeactivate(): Observable<boolean> {
    if(!this.checkIfItemsAreIdentical()){
      return new Observable<boolean>(observer => {
        this.confirmationService.confirm({
          message: 'Kaydedilmemiş değişiklikleriniz var. Sayfadan çıkmak istediğinize emin misiniz?',
          header: 'Kaydedilmemiş Değişiklikler',
          icon: 'pi pi-exclamation-triangle',
          acceptButtonStyleClass: 'btn btn-success hide-duplicated-icon',
          rejectButtonStyleClass: 'btn btn-secondary mr-2',
          acceptLabel: 'Evet',
          rejectLabel: 'Hayır',
          accept: () => {
            observer.next(true);  // User confirmed navigation
            observer.complete();
          },
          reject: () => {
            observer.next(false); // User canceled navigation
            observer.complete();
          }
        });
      });
    }
  }

}
